import React, { useMemo } from "react";
import { StyleSheet, Text, View } from "react-native";
import {
  ClassParticipationCountHeader,
  ClassParticipationCountHeaderItem,
} from "@/components/ClassParticipationCountHeader";
import { TeamList } from "@/components/TeamList";
import useGetSignUps, { NetworkTeam } from "@/hooks/useGetSignUpsMaster";
import useStyles, { UseStylesHookOptions } from "@/hooks/useStyles";

type TeamsByCarClass = {
  [carClass: string]: NetworkTeam[];
};

interface ClassSectionProps {
  title: string;
  teams: NetworkTeam[];
}

const ClassSection: React.FC<ClassSectionProps> = ({
  title = "Class",
  teams,
}) => {
  const styles = useStyles(makeStyles);
  return (
    <View>
      <View style={styles.classSectionHeader}>
        <Text style={styles.classSectionHeaderTitle}>{title}</Text>
      </View>
      <TeamList
        style={styles.teamList}
        contentContainerStyle={styles.teamListContentContainer}
        teams={teams.map(({ drivers, ...item }) => ({
          ...item,
          data: drivers,
        }))}
      />
    </View>
  );
};

export interface SignUpListProps {}

export const SignUpList: React.FC<SignUpListProps> = () => {
  const { data: { signUpCounts = {} } = {} } = useGetSignUps();
  const styles = useStyles(makeStyles);

  const headerCounts: ClassParticipationCountHeaderItem[] = useMemo(() => {
    return Object.entries(signUpCounts).map(([name, classDetails]) => ({
      name,
      count: classDetails.count,
      color: classDetails.color,
    }));
  }, [signUpCounts]);

  const teams: TeamsByCarClass = useMemo(() => {
    return Object.entries(signUpCounts).reduce(
      (acc, [key, { teams }]) => ({
        ...acc,
        [key]: teams,
      }),
      {}
    );
  }, [signUpCounts]);

  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <Text style={styles.headerPageTitle}>
          FTR Events S5 Sign Up Tracker
        </Text>
        <Text style={styles.headerDetail}>
          Sign up form here: https://forms.gle/4jVnK6a13AP1YvSXA
        </Text>
        <Text style={styles.headerDetail}>
          Discord here: https://discord.gg/gYuW9Gk8yA
        </Text>
      </View>

      <View style={styles.classParticipationContainer}>
        <Text style={styles.classParticipationTitle}>Sign ups by class</Text>
        <ClassParticipationCountHeader counts={headerCounts} />
      </View>

      <View style={styles.classParticipantSection}>
        <Text style={styles.classParticipantSectionTitle}>
          {"Team sign ups (click to expand and see line-up)"}
        </Text>
        <ClassSection title="GT3" teams={teams["GT3"] || []} />
        <ClassSection title="GT4" teams={teams["GT4"] || []} />
        <ClassSection title="TCR" teams={teams["TCR"] || []} />
      </View>
    </View>
  );
};

const makeStyles: UseStylesHookOptions = (colors) =>
  StyleSheet.create({
    container: {
      flex: 1,
    },
    headerContainer: {
      paddingBottom: 20,
    },
    headerPageTitle: {
      fontSize: 56,
      fontWeight: "bold",
      color: colors.text,
      paddingBottom: 10,
    },
    headerDetail: {
      fontSize: 18,
      color: colors.text,
    },
    classParticipationContainer: {},
    classParticipantSectionTitle: {
      fontSize: 24,
      color: colors.text,
    },
    classParticipationTitle: {
      fontSize: 24,
      color: colors.text,
    },
    classSectionHeaderTitle: {
      paddingVertical: 10,
      fontSize: 36,
      fontWeight: "bold",
      color: colors.text,
    },
    classParticipantSection: {
      paddingTop: 20,
    },
    teamList: {
      backgroundColor: colors.card,
    },
    teamListContentContainer: {
      paddingHorizontal: 40,
    },
  });

export default SignUpList;
