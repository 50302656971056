import { LinkingOptions } from "@react-navigation/native";
import { AppParamList } from "./types";

const linking: LinkingOptions<AppParamList> = {
  prefixes: ["https://treehousetechnology.io", "treehousetechnology://"],
  config: {
    screens: {
      Home: "home",
      About: "about",
      Services: "services",
      Products: "products",
      Contact: "contact",

      FTREventsSignUps: "ftrevents/signups",

      NotFound: "*",
    },
  },
};

export default linking;
