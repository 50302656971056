import React, { useState } from "react";
import {
  SectionList,
  SectionListProps,
  StyleSheet,
  Text,
  View,
  SectionListData,
  Pressable,
} from "react-native";
import useStyles, { UseStylesHookOptions } from "@/hooks/useStyles";

interface DriverListRowProps {
  name: string;
  driverId: string | number;
  iRating: string | number;
  license: string;
}

const DriverListRow: React.FC<DriverListRowProps> = ({
  name,
  driverId,
  iRating,
  license,
}) => {
  const styles = useStyles(makeStyles);

  return (
    <View style={styles.rowContainer}>
      <Text style={styles.rowLabel}>{name}</Text>
      <Text style={styles.rowLabel}>{driverId}</Text>
      <Text style={styles.rowLabel}>{iRating}</Text>
      <Text style={styles.rowLabel}>{license}</Text>
    </View>
  );
};

interface TeamListRowProps {
  name: string;
  teamId: string | number;
  car: string;
  isPro: boolean;
  carNumber: number | string;
}

const TeamListRow: React.FC<TeamListRowProps> = ({
  name,
  teamId,
  car,
  isPro,
  carNumber,
}) => {
  const styles = useStyles(makeStyles);
  return (
    <View style={styles.rowContainer}>
      <Text style={styles.rowLabel}>{`#${carNumber}`}</Text>
      <Text style={styles.rowLabel}>{name}</Text>
      <Text style={styles.rowLabel}>{teamId}</Text>
      <Text style={styles.rowLabel}>{car}</Text>
      <Text style={styles.rowLabel}>{isPro ? "Yes" : "No"}</Text>
    </View>
  );
};

type TeamListSection = SectionListData<DriverListRowProps, TeamListRowProps>;

type BaseTeamListProps = SectionListProps<DriverListRowProps, TeamListSection>;

export interface TeamListProps
  extends Omit<
    BaseTeamListProps,
    | "sections"
    | "renderItem"
    | "renderSectionHeader"
    | "ListHeaderComponent"
    | "extraData"
  > {
  teams: TeamListSection[];
}

export const TeamList: React.FC<TeamListProps> = ({
  teams,
  style,
  contentContainerStyle,
  ...props
}) => {
  const styles = useStyles(makeStyles);
  const [expandedIndexes, setExpandedIndexes] = useState<(string | number)[]>(
    []
  );

  return (
    <SectionList
      {...props}
      style={[styles.container, style]}
      contentContainerStyle={[styles.contentContainer, contentContainerStyle]}
      sections={teams}
      extraData={expandedIndexes}
      ListHeaderComponent={() => (
        <View style={[styles.rowContainer, styles.headerContainer]}>
          <Text style={[styles.rowLabel, styles.headerLabel]}>#</Text>
          <Text style={[styles.rowLabel, styles.headerLabel]}>Name</Text>
          <Text style={[styles.rowLabel, styles.headerLabel]}>Team ID</Text>
          <Text style={[styles.rowLabel, styles.headerLabel]}>Car</Text>
          <Text style={[styles.rowLabel, styles.headerLabel]}>Pro</Text>
        </View>
      )}
      renderSectionHeader={({ section: { data: _data, teamId, ...props } }) => (
        <Pressable
          onPress={() => {
            setExpandedIndexes((previous) =>
              previous.includes(teamId)
                ? previous.filter((indexId) => indexId !== teamId)
                : [...previous, teamId]
            );
          }}
        >
          <TeamListRow teamId={teamId} {...props} />
        </Pressable>
      )}
      renderItem={({ item: props, section: { teamId } }) => {
        if (!expandedIndexes.includes(teamId)) {
          return null;
        }

        return <DriverListRow {...props} />;
      }}
    />
  );
};

const makeStyles: UseStylesHookOptions = (colors) =>
  StyleSheet.create({
    container: {
      alignSelf: "stretch",
    },
    contentContainer: {
      // rowGap: 5,
    },
    driverList: {
      flex: 1,
    },
    separator: {
      backgroundColor: colors.primary,
      width: 10,
      height: 10,
    },
    rowContainer: {
      alignSelf: "stretch",
      justifyContent: "space-between",
      flexDirection: "row",
      columnGap: 10,
    },
    headerContainer: {
      paddingBottom: 5,
    },
    headerLabel: {
      fontWeight: "bold",
    },
    rowLabel: {
      color: colors.text,
      alignSelf: "stretch",
      flex: 1,
    },
  });

export default TeamList;
